.container__footer {
  background: #1c1c1c;
  text-align: center;
  height: fit-content;
  padding: 1em;
  .isp-pvt-limited {
    color: #eee;
  }
  @media (max-width: 767px) {
    // margin-bottom:1em;
    z-index: 2;
  }
  &:hover {
    color: #fff;
  }

  &:hover &_text_developer {
    color: #d9a86c;
  }
  &_text {
    font-size: calc(1em + 1vw);
    // padding:.4em;
    color: #fff;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
    &_heart {
      color: red;
    }
    &_developer {
      text-decoration: none;
      color: inherit;
      font-family: "Satisfy", sans-serif;

      &:hover {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
